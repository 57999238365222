<template>
  <div class="order-page my-3">
    <div class="container">
      <div class="row">
        <div
          class="mx-auto mb-3"
          style="min-height:60px;height:100%;"
        >
          <a routerLink="/" t>
            <img
              src="/logo-header.png"
              style="display:block;margin:auto;max-height:60px;max-width:100%;"
            />
          </a>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-xl-10">
          <div class="card">
            <div class="card-body p-0">
              <div v-if="currentStep === 1" class="row">
                <div class="col-md-7 order-form">
                  <h3 class="h3">Create your account</h3>
                  <ValidationObserver
                    v-slot="{ handleSubmit, invalid, touched }"
                    ref="orderForm"
                  >
                    <form
                      class="authentication-form"
                      @submit.prevent="handleSubmit(handleRegister)"
                    >
                      <TextInput
                        v-model="credentials.company_name"
                        placeholder="Enter company name"
                        rules="required"
                        name="company_name"
                      />
                      <div class="row">
                        <div class="col-md-6">
                          <TextInput
                            v-model="credentials.first_name"
                            placeholder="First name"
                            rules="required"
                            name="first_name"
                          />
                        </div>
                        <div class="col-md-6">
                          <TextInput
                            v-model="credentials.last_name"
                            placeholder="Last name"
                            rules="required"
                            name="last_name"
                          />
                        </div>
                      </div>
                      <TextInput
                        v-model="credentials.email"
                        autocomplete="new-account"
                        placeholder="Enter email"
                        rules="required|email"
                        name="email"
                      />
                      <PhoneInput
                        v-model="credentials.phone"
                        autocomplete="new-account"
                        placeholder="Phone number"
                        rules="required|phone"
                        name="phone"
                      />
                      <div class="row">
                        <div class="col-12 mb-2">
                          <small>
                            Enter a name for your white label subdomain, no
                            spaces or special characters.
                          </small>
                          <span
                            v-b-tooltip.hover
                            style="vertical-align:sub;cursor:pointer;"
                            title="Enter a name for your white label subdomain, this will be where your application resides. For example you could call it localmarketing and your application would be localmarketing.broadcastengine.io, no capitals or special characters are allowed. You can customize this to your own URL after registering."
                            ><i class="uil uil-question-circle"></i
                          ></span>
                        </div>
                        <div class="col-7">
                          <TextInput
                            v-model="credentials.subdomain"
                            placeholder="Subdomain"
                            rules="required|subdomain"
                            name="subdomain"
                          />
                        </div>
                        <div class="col-5">
                          <TextInput
                            :value="whitelabelDomain"
                            :readonly="true"
                          />
                        </div>
                      </div>
                      <hr />
                      <AddCreditCard ref="cardForm" />
                      <img
                        style="max-width:200px;display:block;margin:0 auto;"
                        src="@assets/images/credit-card.png"
                        alt="Credit card"
                      />
                      <p class="text-center mt-3" style="font-size:13px;">
                        We use a secure payment gateway – Stripe – that
                        processes payments on our behalf. Customers Engine does
                        not store credit or debit card details on our website.
                        All payment data is securely stored by a third-party
                        PCI-compliant payment gateway.
                      </p>
                      <hr />
                      <div v-if="plan" class="text-center">
                        <!-- eslint-disable vue/no-v-html -->
                        <span v-html="plan.description"></span>
                        <!--eslint-enable-->
                      </div>

                      <b-form-group class="mt-4 mb-1">
                        <b-button
                          variant="primary"
                          class="btn-block"
                          :disabled="loadingRegister || (invalid && touched)"
                          type="submit"
                        >
                          <b-spinner v-if="loadingRegister" small />
                          <span v-else>Complete My Order</span>
                        </b-button>
                      </b-form-group>
                      <p class="text-center">
                        <small
                          >By submitting the Complete Order button above you
                          agree to our
                          <a
                            href="https://www.customerengine.io/terms-of-service"
                            target="_blank"
                            >Terms Of Service</a
                          >
                          &
                          <a
                            href="https://www.customerengine.io/privacy-policy"
                            target="_blank"
                            >Privacy Policy</a
                          ></small
                        >
                      </p>
                    </form>
                  </ValidationObserver>
                </div>
                <div class="col-md-5 order-details">
                  <h3 class="h3" style="font-weight: 700"
                    >Here's What You'll Get:</h3
                  >
                  <ul>
                    <li>
                      <feather size="18" type="check"></feather>
                      <b>White Label Desktop App:</b> Out of the gate you can to
                      use your own Domain, add your logo and make it your own
                      platform!
                    </li>
                    <li>
                      <feather size="18" type="check"></feather>
                      <b>Business Accounts:</b> Create business accounts under
                      your agency for all your customers.
                    </li>
                    <li>
                      <feather size="18" type="check"></feather>
                      <b>Coupon Wizard:</b> Easily create offers & coupons for
                      desktop, mobile & print.
                    </li>
                    <li>
                      <feather size="18" type="check"></feather>
                      <b>SMS Broadcast:</b> Broadcast product launches, sales,
                      brand marketing, new content, and more to reach
                      subscribers at scale.
                    </li>
                    <li>
                      <feather size="18" type="check"></feather>
                      <b>Keyword Lists:</b> Create subscriber segments based on
                      keywords and optimize your campaigns.
                    </li>
                    <li>
                      <feather size="18" type="check"></feather>
                      <b>Business Dashboard:</b> Integrated reporting and
                      analytics to easily analyze the performance of your
                      customers campaigns.
                    </li>
                    <li>
                      <feather size="18" type="check"></feather>
                      <b>Set & Forget Technology:</b> Install the code once and
                      control everything from a simple dashboard.
                    </li>
                  </ul>
                  <div class="secure-images">
                    <img src="@assets/images/secure-images.png" />
                    <div class="row mt-2 text-center">
                      <div class="col-6">
                        <small>Privacy Guaranteed</small>
                      </div>
                      <div class="col-6">
                        <small>100% Secure Information</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="currentStep === 2" class="step-2 text-center">
                <h3 class="h3 mt-4 mb-3"
                  ><span class="text-primary">WAIT!</span> Special ONE TIME
                  Offer</h3
                >
                <h4 class="h4 text-primary"
                  >Never Miss This Chance... You Will Never See This Offer
                  Again...</h4
                >
                <hr />
                <div class="mt-1 mb-4">
                  <iframe
                    :src="
                      plan.video
                        ? plan.video
                        : `https://player.vimeo.com/video/392289495?title=0&byline=0&portrait=0`
                    "
                    width="640"
                    height="360"
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowfullscreen
                  ></iframe>
                </div>
                <div class="box-upgrade">
                  <button
                    class="btn btn-warning"
                    :disabled="loadingRegister"
                    @click="subscribeUnlimited"
                  >
                    <b-spinner v-if="loadingRegister" small />
                    <span v-else
                      >Upgrade Today To Agency Growth & Get
                      {{ plan.sale_businesses }} Accounts For ${{
                        plan.sale_amount
                      }}/mo</span
                    >
                  </button>
                </div>
                <h3 class="h3" @click="subscribeUnlimited"
                  >Upgrade Today To Agency Growth & Get
                  {{ plan.sale_businesses }} Accounts For ${{
                    plan.sale_amount
                  }}
                  Per Month</h3
                >
                <div class="pt-2 pb-4">
                  <a href="javascript:void(0);" @click.prevent="nextStep">
                    No Thanks, I don't want
                    {{ plan.sale_name || 'unlimited' }} accounts & I understand
                    I will never see this offer again
                  </a>
                </div>
              </div>
              <div v-if="currentStep === 3" class="step-3 text-center">
                <h3 class="h3 mt-4 mb-4 text-primary"
                  >Congratulation - Your order has been successfully
                  completed.</h3
                >
                <div class="mb-4">
                  <p class="h6 text-primary">
                    Please check your inbox to get your login credential.
                  </p>
                </div>
                <div class="pb-4 pt-4">
                  <router-link
                    class="btn btn-outline-dark"
                    :to="{ name: 'agency.businesses.index' }"
                    >GO TO DASHBOARD</router-link
                  >
                </div>
              </div>
            </div>
          </div>

          <div v-if="currentStep === 1" class="row mt-3">
            <div class="col-12 text-center">
              <p class="text-muted">
                Already have an account?
                <router-link
                  :to="{ name: 'login' }"
                  class="text-primary font-weight-bold ml-1"
                >
                  <b>Login</b>
                </router-link>
              </p>
            </div>
          </div>
        </div>
        <p class="text-center text-muted mt-5" style="font-size:13px;">
          Copyright {{ year }} All Rights ReservedTerms of ServicePrivacy Policy
          <br />
          11505 Burnham Drive, Suite 204, Gig Harbor, WA 98332
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import AddCreditCard from '../Common/AddCreditCard'
import moment from 'moment-timezone'
import Vue from 'vue'
import postscribe from 'postscribe'

export default {
  components: { AddCreditCard },

  data() {
    return {
      credentials: {
        first_name: '',
        email: '',
        company_name: '',
        plan: '',
        payment_method: '',
      },
      currentStep: 1,
      plan: null,
      loadingRegister: false,
      year: moment().format('Y'),
      timer: null,
    }
  },

  computed: {
    whitelabelDomain() {
      return process.env.VUE_APP_WHITELABEL_DOMAIN
    },
  },

  watch: {
    currentStep: function(newVal, oldVal) {
      if (newVal !== oldVal && newVal === 3) {
        if (process.env.NODE_ENV === 'production')
        {
          this.addScriptPost()
        }
      }
    },
  },

  mounted() {
    // Check plan
    if (!this.$route.query.plan) {
      this.$router.push('/')
    } else {
      this.credentials.first_name = this.$route.query.first_name
      this.credentials.email = this.$route.query.email
      this.getPlan()
    }
  },

  methods: {
    nextStep() {
      this.currentStep++
    },

    clearTimer() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
    },

    addScript() {
      // Init Script
      if ($('#custom-order-script').length) {
        $('#custom-order-script').next().remove()
        $('#custom-order-script').remove()
      }

      const scripts = this.$store.getters['auth/orderPageScript']

      // Check Total Scripts
      if (!$('#custom-order-script').length) {
        $('body').append(`<div id="custom-order-script"></div>`)

        postscribe('#custom-order-script', scripts, {
          done: () => {
          }
        })
      }
    },

    addScript1() {
      // Init Script
      if ($('#custom-order-script1').length) {
        $('#custom-order-script1').remove()
      }

      const scripts = this.$store.getters['auth/orderPageScript1']

      // Check Total Scripts
      if (!$('#custom-order-script1').length) {
        $('body').append(`<div id="custom-order-script1"></div>`)

        postscribe('#custom-order-script1', scripts, {
          done: () => {
          }
        })
      }
    },

    addScriptPost() {
      // Init Script
      if ($('#custom-order-script').length) {
        $('#custom-order-script').next().remove()
        $('#custom-order-script').remove()
      }

      const scripts = this.$store.getters['auth/orderPageScriptThankyou']

      // Check Total Scripts
      if (!$('#custom-order-script').length) {
        $('body').append(`<div id="custom-order-script"></div>`)

        postscribe('#custom-order-script', scripts, {
          done: () => {
          }
        })
      }
    },

    getPlan() {
      this.$store
        .dispatch('subscription/getPlanByName', this.$route.query.plan)
        .then((res) => {
          this.plan = res.data
          this.credentials.plan = res.data.id
          if (process.env.NODE_ENV === 'production' && this.plan.id === 'plan_KpHapgzWSzCEHg') {
            this.addScript()
          }
          if (process.env.NODE_ENV === 'production' && this.plan.id === 'plan_HnlTjEws9d9dnp') {
            // this.addScript1()
          }
        })
        .catch(() => {
          window.location.replace('/')
        })
    },

    async validateCard() {
      this.loadingRegister = true
      this.credentials.payment_method = null

      await this.$refs.cardForm
        .validate()
        .then((paymentMethod) => {
          this.loadingRegister = false
          this.credentials.payment_method = paymentMethod
        })
        .catch(() => {
          this.loadingRegister = false
        })
    },

    async handleRegister() {
      if (this.$store.getters['auth/isLoggedIn'])
      {
        Vue.$toast.open({
          message: 'You are already logged in. Please logout and try again!',
          type: 'default',
          duration: 5000,
        })
        return;
      }
      await this.validateCard()
      if (!this.credentials.payment_method) return

      this.loadingRegister = true
      this.$store
        .dispatch('auth/registerAgency', this.credentials)
        .then(() => {
          this.loadingRegister = false
          if (this.plan.sale_name) {
            this.nextStep()
          } else {
            this.currentStep = 3
          }
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.orderForm.setErrors(err.response.data.errors)
          }
          this.loadingRegister = false
        })
    },

    subscribeUnlimited() {
      this.loadingRegister = true

      this.$store
        .dispatch('subscription/subscribeUnlimited')
        .then((res) => {
          this.loadingRegister = false
          this.currentStep = 3
        })
        .catch(() => {
          this.loadingRegister = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.order-form {
  padding: 30px 40px;
}
.order-details {
  padding: 30px;
  background: #ffffe9;
  ul {
    padding: 0;
    margin-top: 30px;
    list-style: none;
    li {
      position: relative;
      padding-bottom: 15px;
      padding-left: 25px;
      .feather {
        position: absolute;
        top: 2px;
        left: 0;
        color: #0ca90c;
      }
    }
  }
}
.order-page {
  .box-upgrade {
    margin-bottom: 30px;
    .btn {
      max-width: 100%;
      padding: 15px 100px;
    }
  }
  .secure-images img {
    width: 300px;
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
}
</style>
